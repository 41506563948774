.acknowledgement_wrapper__1MJo2 {
  width: "90%";
  margin: "0 auto";
  margin-top: "80px";
}
.acknowledgement_shadow__IVfnf{
  background: #F6F6F6;
  height: 250px
}
.acknowledgement_cardImage__-E_ON {
  width: 387px;
  margin: -110px auto 0px;
  height: 224px;
}
.acknowledgement_cardShadow__39LYX {
  height: 189px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 16px 9px 0px;
}
.acknowledgement_cardContent__2i3t8 {
  position: relative;
  top: -194px;
  left: 22px;
  z-index: 100;
  color: rgb(244, 127, 34);
  letter-spacing: 3.36px;
  font-size: 24px;
  font-weight: bold;
  font-family: "Noto Sans";
  height: 177px;
  width: 353px;
}
.acknowledgement_title__25Ib8 {
  color: rgb(244, 127, 31);
  opacity: 1;
  font-size: 28px;
  line-height: 30px;
  font-weight: 700;
  font-family: "Noto Sans TC";
  text-align: center;
  margin-top: 50px;
}
.acknowledgement_caption__3GXfZ {
  color: #939597;
  font-size: 20px;
  line-height: 26px;
  font-family: "Noto Sans";
  text-align: center;
  width: 510px;
  margin: 26px auto;
  margin-bottom: 30px;
}
.acknowledgement_deliveryType__3-mp_ {
  color: rgb(30, 159, 218);
  font-size: 36px;
  font-weight: bolder;
  font-family: "Noto Sans";
  text-align: center;
  margin-top: 10px;
}
.acknowledgement_purchaseInfo__2I77a {
  text-align: center;
  color: rgb(21, 20, 21);
  font-size: 18px;
  font-weight: bold;
  font-family: "Noto Sans TC";
}
.acknowledgement_team__uhjrd {
  text-align: center;
  color: rgb(147, 149, 151);
  font-size: 17px;
  font-family: "Noto Sans TC";
  width: 570px;
  margin: 18px auto;
}
.acknowledgement_button__2iV4N {
  bottom: 0px !important;
  text-align: center !important;
  width: 210px !important;
  padding: 10px !important;
  background-color: rgb(244, 127, 31) !important;
  color: white !important;
  font-size: 16px !important;
  font-family: "Noto Sans" !important;
  border-radius: 0px !important;
  margin: 20px 0px !important;
}
.acknowledgement_appreciation__FBviu {
  width: 50%;
  /*display: flex;*/
  justify-content: center;
}

.acknowledgement_acknowledgementWelcomeImg__3cU3t {
  width: 100%;
  object-fit: contain;
}

.acknowledgement_logoSection__2cz-8 {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
}
.acknowledgement_name__XsHdf {
  color: white;
  font-size: 15px;
}

.acknowledgement_cardNumber__3fRPh {
  font-size: 35px;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

body{
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .acknowledgement_wrapper__1MJo2{
    margin-top: 20px;
  }
  .acknowledgement_cardImage__-E_ON {
    width: 300px !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px;
    margin: -110px auto 0px;
    height: 174px !important;
  }
  .acknowledgement_shadow__IVfnf{
    height: 190px
  }
  .acknowledgement_cardShadow__39LYX {
    height: 169px;
  }
  .acknowledgement_logoSection__2cz-8 {
    margin-bottom: 10px;
  }
  .acknowledgement_dalmiaLogo__1Xs3s {
    width: 30%;
    padding: 12px 0px;
  }
  .acknowledgement_appreciation__FBviu {
    width: inherit;
  }

  .acknowledgement_purchaseInfo__2I77a {
    font-size: 12px;
    margin-bottom: 30px;
  }

  .acknowledgement_cardContent__2i3t8 {
    position: relative;
    top: -174px;
    left: 22px;
    z-index: 100;
    color: rgb(244, 127, 34);
    letter-spacing: 3.36px;
    font-size: 24px;
    font-weight: bold;
    font-family: "Noto Sans";
    height: 177px;
    width: 263px;
  }
  .acknowledgement_caption__3GXfZ {
    font-size: 15px;
    width: 100%;
    line-height: 20px;
  }
  .acknowledgement_team__uhjrd {
    width: 100%;
    font-size: 12px;
  }
  .acknowledgement_deliveryType__3-mp_ {
    font-size: 20px;
  }
  .acknowledgement_name__XsHdf {
    font-size: 12px;
  }
  .acknowledgement_cardNumber__3fRPh {
    font-size: 28px;
  }
}



.BackgroundVideo_Container__210jC {
  position: relative;
  min-height: 300px;
  max-height: 800px;
  overflow: hidden;
}
* {
  margin: 0;
  font-family: Noto Sans;
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Style the video: 100% width and height to cover the entire window */
.BackgroundVideo_Video__2FB9j {
  width: 100%;
  height: 100%;
}

/* Add some content at the bottom of the video/page */
.BackgroundVideo_Content__1MufR {
  position: absolute;
  top: 0;
  color: #f1f1f1;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 99.5%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  background: url("/static/media/Group 3504.a72b9238.svg") no-repeat;
  background-size:contain
}
.BackgroundVideo_SubContent__3a1OB {
  padding: 10px;
  height: 55%;
  width: 48%;
}
.BackgroundVideo_SubContent__3a1OB h1 {
  font-size: 72px;
  font-weight: bolder;
}
.BackgroundVideo_SubContent__3a1OB p {
  color: #eeeeeead;
  width: 350px;
  font-size: 32px;
  font-weight: bolder;
  color: #f47f22;
  margin: 20px 0px;
}
.BackgroundVideo_SubContent__3a1OB button {
  font-weight: bold;
  margin-top: 30px;
  color: #f1f1f1;
  border: #f1f1f1 1px solid;
  padding: 10px 5px;
  background-color: hsla(0, 0%, 0%, 0);
  font-size: 1.25rem;
  border-radius: 5px;
}
.BackgroundVideo_SubContent__3a1OB button:hover {
  background-color: #f1f1f1;
  color: #000000c0;
}

.BackgroundVideo_SubContent__3a1OB img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  border: #f1f1f1 1px solid;
}
@media screen and (min-device-width: 0px) and (max-device-width: 600px){
  .BackgroundVideo_Container__210jC {
    min-height: 184px;
  }
  .BackgroundVideo_Content__1MufR {
    width: 100%;
    height: 98%;
  }
  .BackgroundVideo_SubContent__3a1OB {
    height: 85%;
    width: 55%;
  }
  .BackgroundVideo_SubContent__3a1OB h1 {
    font-size: 30px;
  }
  .BackgroundVideo_SubContent__3a1OB p {
    font-size: 14px;
    margin: 10px 0px;
    width: 172px;
  }
  .BackgroundVideo_SubContent__3a1OB div {
    font-size: 14px !important;
  }
  .BackgroundVideo_SubContent__3a1OB button {
    margin-top: 10px !important;
    width: 110px !important;
    font-size: 12px !important;
    height: 30px !important;
    padding: 0px !important;
  }
}
@media screen and (min-device-width: 0px) and (max-device-width: 340px){
  .BackgroundVideo_SubContent__3a1OB {
    height: 100%;
    width: 55%;
  }
}
@media screen and (min-device-width: 600px) and (max-device-width: 700px){
  .BackgroundVideo_SubContent__3a1OB h1 {
    font-size: 35px;
  }
  .BackgroundVideo_SubContent__3a1OB p {
    font-size: 22px;
  }
}
@media screen and (min-device-width: 700px) and (max-device-width: 1000px){
  .BackgroundVideo_Container__210jC {
    min-height: 207px;
  }
  .BackgroundVideo_Content__1MufR {
    width: 100%;
    height: 99%;
  }
  .BackgroundVideo_SubContent__3a1OB {
    padding: 10px;
    height: 55%;
    width: 52%;
  }
  .BackgroundVideo_SubContent__3a1OB h1 {
    font-size: 42px;
  }
  .BackgroundVideo_SubContent__3a1OB p {
    font-size: 31px;
    margin: 10px 0px;
    width: 350px;
  }
  .BackgroundVideo_SubContent__3a1OB div {
    font-size: 14px !important;
    /* margin: 0 !important; */
  }
  .BackgroundVideo_SubContent__3a1OB button {
    margin-top: 20px !important;
    width: 110px !important;
    font-size: 12px !important;
    height: 25px !important;
    padding: 0px !important;
  }
}

.errorMessage {
    font-size: 12px;
    color: red;
    margin-top: 10px;
    text-align: center;
}

.cameraHeader {
    font-size: 12px;
    margin: 1rem;
}


.cameraFieldsGrid {
    display: grid;
    grid-template-columns: 2fr repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 30px;
    margin: 1rem 0rem;
}

.itemCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cameraFieldLabel {
    font-weight: bold;
    font-size: 14px;
    margin-top: 5px;
    text-align: center;
    justify-content: end;
}

.react-html5-camera-photo>img, .react-html5-camera-photo>video {
    width: 768px;
}

.cameraFieldImageContainer {
    width: 45px;
    height: 45px;
}

@media screen and (min-device-width: 0px) and (max-device-width: 700px) {
    .react-html5-camera-photo>img, .react-html5-camera-photo>video {
        width: 100% ;
    }
}

@media screen and (min-device-width: 700px) and (max-device-width: 1100px) {
    .react-html5-camera-photo>img, .react-html5-camera-photo>video {
        width: 468px ;
    }
}

.survey-form_surveyFormContainer__1fw8i{
	display: flex;
	flex-direction: column;
	overflow-x: auto;
	/* position: relative; */
	/* border:2px solid black; */
	/* width:100vw; */
	/* height: 100vh; */
}

.survey-form_img__13zjo {	
	visibility: hidden;
	width: 100vw;
}

.survey-form_surveyImg__2w5dc{
	margin-top: 60px;
	margin-bottom: 8px;
	background: transparent url("/static/media/Group 3976.9bbe09e4.svg") 0% 0% no-repeat padding-box;
	position: relative;
	width: 100vw;
	background-size: contain;
	background-color: #F6F6F6;
	display: flex;
	align-items: center;
}

.survey-form_surveyTextContainer__2RxcH{
	position: absolute;
	display: flex;
	margin-left:5vw;
	/* transform: translateY(-33.33%); */
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.survey-form_surveyText__17J06{
	font-family: 'Noto Sans TC', sans-serif;
	font-size: 5vw;
	font-weight: bold;
	color: #1EA0DA;
}

.survey-form_takePointsContainer__1Lorf{
	display: flex;
	flex-direction: row;
}

.survey-form_radioContainer__2euyV{
	background-color: white;
	padding: 3vw;
	margin: 8px 16px;
	font-size: 2vw;
	font-family: 'Noto Sans TC', sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: flex-start;
}

.survey-form_rowWrapper__7lXVX{
	/* background-color: #1EA0DA; */
	width: 100%;
	display:flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.survey-form_rowRadioContainer__1NTKU{
	margin-top: 7vw;
	width: 100%;
	display:flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	/* flex-wrap: wrap; */
	margin-left:2vw;
}

.survey-form_optionsRowContainer__1RqCy{
	display: flex;
	width: 100%;
	/* margin-right: 10%; */
	flex-direction: row;
	align-items: center;
	margin-top: 1vw;
	/* background-color: yellow; */
}

.survey-form_optionsColContainer__3f_Tc{
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-between;
	align-items: center;
	margin-right: 5vw;
}

.survey-form_radio__3yyGj{
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
  	outline: none;
	border-radius: 50%;
	width: 1.5vw;
	height: 1.5vw;
	border: 2px solid #F47F1F;
}

.survey-form_radio__3yyGj:hover{
	cursor: pointer;
}

.survey-form_radio__3yyGj:checked{
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
	border-radius: 50%;
	width: 1.5vw;
	height: 1.5vw;
	border: 2px solid #F47F1F;
	background-color: #F47F1F;
	background-clip: content-box;
	padding: 0.2vw;
}

.survey-form_emoji__32oDB {
	height: 50px;
	width: 50px;
}

.survey-form_err__KbxT4{
	color: darkred;
	opacity: 0.7;
	font-size: 16px;
	text-align: center;
	margin: 2%;
}

.survey-form_btn__29OF1{
	/* position:absolute; */
	margin-top: 5vw;
	/* bottom:0%; */
	background-color: #F47F1F;
	color: white;
	border: none;
	outline:none;
	font-size: 2vw;
	font-weight: bold;
	padding: 2vw;
	/* height: 10vw; */
	text-align: center;
	font-family: 'Noto Sans TC', sans-serif;
	width:100%;
}

.survey-form_btn__29OF1:hover{
	opacity: 0.9;
	cursor: pointer;
}

@media only screen and (max-width:560px){

	.survey-form_surveyFormContainer__1fw8i{
		height: 100vh;
	}
	.survey-form_takePointsContainer__1Lorf{
		flex-direction: column;
	}
	.survey-form_surveyText__17J06{
		font-size: 5vw;
	}
	.survey-form_radio__3yyGj{
		width: 3.5vw;
		height: 3.5vw;
		border: 0.4vw solid #F47F1F;
	}
	.survey-form_radio__3yyGj:checked{
		width: 3.5vw;
		height: 3.5vw;
		padding: 0.5vw;
		border: 0.4vw solid #F47F1F;
	}
	.survey-form_radioContainer__2euyV{
		font-size: 3vw;
		padding: 5vw;
	}
	.survey-form_emoji__32oDB{
		height: 30px;
		width: 30px;
	}
	.survey-form_btn__29OF1 {
		bottom: 0;
		font-size: 5vw;
		padding: 4vw;
	}

	.survey-form_optionsRowContainer__1RqCy{
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 5vw;
	}
}
	
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

