

.Container {
  position: relative;
  min-height: 300px;
  max-height: 800px;
  overflow: hidden;
}
* {
  margin: 0;
  font-family: Noto Sans;
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Style the video: 100% width and height to cover the entire window */
.Video {
  width: 100%;
  height: 100%;
}

/* Add some content at the bottom of the video/page */
.Content {
  position: absolute;
  top: 0;
  color: #f1f1f1;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 99.5%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  background: url('../assets/images/Group 3504.svg') no-repeat;
  background-size:contain
}
.SubContent {
  padding: 10px;
  height: 55%;
  width: 48%;
}
.SubContent h1 {
  font-size: 72px;
  font-weight: bolder;
}
.SubContent p {
  color: #eeeeeead;
  width: 350px;
  font-size: 32px;
  font-weight: bolder;
  color: #f47f22;
  margin: 20px 0px;
}
.SubContent button {
  font-weight: bold;
  margin-top: 30px;
  color: #f1f1f1;
  border: #f1f1f1 1px solid;
  padding: 10px 5px;
  background-color: hsla(0, 0%, 0%, 0);
  font-size: 1.25rem;
  border-radius: 5px;
}
.SubContent button:hover {
  background-color: #f1f1f1;
  color: #000000c0;
}

.SubContent img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  border: #f1f1f1 1px solid;
}
@media screen and (min-device-width: 0px) and (max-device-width: 600px){
  .Container {
    min-height: 184px;
  }
  .Content {
    width: 100%;
    height: 98%;
  }
  .SubContent {
    height: 85%;
    width: 55%;
  }
  .SubContent h1 {
    font-size: 30px;
  }
  .SubContent p {
    font-size: 14px;
    margin: 10px 0px;
    width: 172px;
  }
  .SubContent div {
    font-size: 14px !important;
  }
  .SubContent button {
    margin-top: 10px !important;
    width: 110px !important;
    font-size: 12px !important;
    height: 30px !important;
    padding: 0px !important;
  }
}
@media screen and (min-device-width: 0px) and (max-device-width: 340px){
  .SubContent {
    height: 100%;
    width: 55%;
  }
}
@media screen and (min-device-width: 600px) and (max-device-width: 700px){
  .SubContent h1 {
    font-size: 35px;
  }
  .SubContent p {
    font-size: 22px;
  }
}
@media screen and (min-device-width: 700px) and (max-device-width: 1000px){
  .Container {
    min-height: 207px;
  }
  .Content {
    width: 100%;
    height: 99%;
  }
  .SubContent {
    padding: 10px;
    height: 55%;
    width: 52%;
  }
  .SubContent h1 {
    font-size: 42px;
  }
  .SubContent p {
    font-size: 31px;
    margin: 10px 0px;
    width: 350px;
  }
  .SubContent div {
    font-size: 14px !important;
    /* margin: 0 !important; */
  }
  .SubContent button {
    margin-top: 20px !important;
    width: 110px !important;
    font-size: 12px !important;
    height: 25px !important;
    padding: 0px !important;
  }
}
