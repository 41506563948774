.errorMessage {
    font-size: 12px;
    color: red;
    margin-top: 10px;
    text-align: center;
}

.cameraHeader {
    font-size: 12px;
    margin: 1rem;
}


.cameraFieldsGrid {
    display: grid;
    grid-template-columns: 2fr repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 30px;
    margin: 1rem 0rem;
}

.itemCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cameraFieldLabel {
    font-weight: bold;
    font-size: 14px;
    margin-top: 5px;
    text-align: center;
    justify-content: end;
}

.react-html5-camera-photo>img, .react-html5-camera-photo>video {
    width: 768px;
}

.cameraFieldImageContainer {
    width: 45px;
    height: 45px;
}

@media screen and (min-device-width: 0px) and (max-device-width: 700px) {
    .react-html5-camera-photo>img, .react-html5-camera-photo>video {
        width: 100% ;
    }
}

@media screen and (min-device-width: 700px) and (max-device-width: 1100px) {
    .react-html5-camera-photo>img, .react-html5-camera-photo>video {
        width: 468px ;
    }
}
