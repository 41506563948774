.surveyFormContainer{
	display: flex;
	flex-direction: column;
	overflow-x: auto;
	/* position: relative; */
	/* border:2px solid black; */
	/* width:100vw; */
	/* height: 100vh; */
}

.img {	
	visibility: hidden;
	width: 100vw;
}

.surveyImg{
	margin-top: 60px;
	margin-bottom: 8px;
	background: transparent url('../assets/images/Group\ 3976.svg') 0% 0% no-repeat padding-box;
	position: relative;
	width: 100vw;
	background-size: contain;
	background-color: #F6F6F6;
	display: flex;
	align-items: center;
}

.surveyTextContainer{
	position: absolute;
	display: flex;
	margin-left:5vw;
	/* transform: translateY(-33.33%); */
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.surveyText{
	font-family: 'Noto Sans TC', sans-serif;
	font-size: 5vw;
	font-weight: bold;
	color: #1EA0DA;
}

.takePointsContainer{
	display: flex;
	flex-direction: row;
}

.radioContainer{
	background-color: white;
	padding: 3vw;
	margin: 8px 16px;
	font-size: 2vw;
	font-family: 'Noto Sans TC', sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: flex-start;
}

.rowWrapper{
	/* background-color: #1EA0DA; */
	width: 100%;
	display:flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.rowRadioContainer{
	margin-top: 7vw;
	width: 100%;
	display:flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	/* flex-wrap: wrap; */
	margin-left:2vw;
}

.optionsRowContainer{
	display: flex;
	width: 100%;
	/* margin-right: 10%; */
	flex-direction: row;
	align-items: center;
	margin-top: 1vw;
	/* background-color: yellow; */
}

.optionsColContainer{
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-between;
	align-items: center;
	margin-right: 5vw;
}

.radio{
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
  	outline: none;
	border-radius: 50%;
	width: 1.5vw;
	height: 1.5vw;
	border: 2px solid #F47F1F;
}

.radio:hover{
	cursor: pointer;
}

.radio:checked{
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
	border-radius: 50%;
	width: 1.5vw;
	height: 1.5vw;
	border: 2px solid #F47F1F;
	background-color: #F47F1F;
	background-clip: content-box;
	padding: 0.2vw;
}

.emoji {
	height: 50px;
	width: 50px;
}

.err{
	color: darkred;
	opacity: 0.7;
	font-size: 16px;
	text-align: center;
	margin: 2%;
}

.btn{
	/* position:absolute; */
	margin-top: 5vw;
	/* bottom:0%; */
	background-color: #F47F1F;
	color: white;
	border: none;
	outline:none;
	font-size: 2vw;
	font-weight: bold;
	padding: 2vw;
	/* height: 10vw; */
	text-align: center;
	font-family: 'Noto Sans TC', sans-serif;
	width:100%;
}

.btn:hover{
	opacity: 0.9;
	cursor: pointer;
}

@media only screen and (max-width:560px){

	.surveyFormContainer{
		height: 100vh;
	}
	.takePointsContainer{
		flex-direction: column;
	}
	.surveyText{
		font-size: 5vw;
	}
	.radio{
		width: 3.5vw;
		height: 3.5vw;
		border: 0.4vw solid #F47F1F;
	}
	.radio:checked{
		width: 3.5vw;
		height: 3.5vw;
		padding: 0.5vw;
		border: 0.4vw solid #F47F1F;
	}
	.radioContainer{
		font-size: 3vw;
		padding: 5vw;
	}
	.emoji{
		height: 30px;
		width: 30px;
	}
	.btn {
		bottom: 0;
		font-size: 5vw;
		padding: 4vw;
	}

	.optionsRowContainer{
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 5vw;
	}
}
	