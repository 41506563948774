.wrapper {
  width: "90%";
  margin: "0 auto";
  margin-top: "80px";
}
.shadow{
  background: #F6F6F6;
  height: 250px
}
.cardImage {
  width: 387px;
  margin: -110px auto 0px;
  height: 224px;
}
.cardShadow {
  height: 189px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 16px 9px 0px;
}
.cardContent {
  position: relative;
  top: -194px;
  left: 22px;
  z-index: 100;
  color: rgb(244, 127, 34);
  letter-spacing: 3.36px;
  font-size: 24px;
  font-weight: bold;
  font-family: "Noto Sans";
  height: 177px;
  width: 353px;
}
.title {
  color: rgb(244, 127, 31);
  opacity: 1;
  font-size: 28px;
  line-height: 30px;
  font-weight: 700;
  font-family: "Noto Sans TC";
  text-align: center;
  margin-top: 50px;
}
.caption {
  color: #939597;
  font-size: 20px;
  line-height: 26px;
  font-family: "Noto Sans";
  text-align: center;
  width: 510px;
  margin: 26px auto;
  margin-bottom: 30px;
}
.deliveryType {
  color: rgb(30, 159, 218);
  font-size: 36px;
  font-weight: bolder;
  font-family: "Noto Sans";
  text-align: center;
  margin-top: 10px;
}
.purchaseInfo {
  text-align: center;
  color: rgb(21, 20, 21);
  font-size: 18px;
  font-weight: bold;
  font-family: "Noto Sans TC";
}
.team {
  text-align: center;
  color: rgb(147, 149, 151);
  font-size: 17px;
  font-family: "Noto Sans TC";
  width: 570px;
  margin: 18px auto;
}
.button {
  bottom: 0px !important;
  text-align: center !important;
  width: 210px !important;
  padding: 10px !important;
  background-color: rgb(244, 127, 31) !important;
  color: white !important;
  font-size: 16px !important;
  font-family: "Noto Sans" !important;
  border-radius: 0px !important;
  margin: 20px 0px !important;
}
.appreciation {
  width: 50%;
  /*display: flex;*/
  justify-content: center;
}

.acknowledgementWelcomeImg {
  width: 100%;
  object-fit: contain;
}

.logoSection {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
}
.name {
  color: white;
  font-size: 15px;
}

.cardNumber {
  font-size: 35px;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

body{
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .wrapper{
    margin-top: 20px;
  }
  .cardImage {
    width: 300px !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px;
    margin: -110px auto 0px;
    height: 174px !important;
  }
  .shadow{
    height: 190px
  }
  .cardShadow {
    height: 169px;
  }
  .logoSection {
    margin-bottom: 10px;
  }
  .dalmiaLogo {
    width: 30%;
    padding: 12px 0px;
  }
  .appreciation {
    width: inherit;
  }

  .purchaseInfo {
    font-size: 12px;
    margin-bottom: 30px;
  }

  .cardContent {
    position: relative;
    top: -174px;
    left: 22px;
    z-index: 100;
    color: rgb(244, 127, 34);
    letter-spacing: 3.36px;
    font-size: 24px;
    font-weight: bold;
    font-family: "Noto Sans";
    height: 177px;
    width: 263px;
  }
  .caption {
    font-size: 15px;
    width: 100%;
    line-height: 20px;
  }
  .team {
    width: 100%;
    font-size: 12px;
  }
  .deliveryType {
    font-size: 20px;
  }
  .name {
    font-size: 12px;
  }
  .cardNumber {
    font-size: 28px;
  }
}
